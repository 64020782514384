






































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import { VueBlock, VueBlockApiCall } from '@/models'
import { VueBlockFragment, VueBlockUpdateFragment } from '../fragments'
import Fields from '@/components/form/Fields.vue'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'
import gql from 'graphql-tag'
import cleanData from '@/utils/gql/cleanData'
import { ApolloError } from 'apollo-client'
import ComponentSelect from '@/components/fields/componentSelect/Field.vue'
import CollectionFieldSelect from '@/components/fields/collectionFieldSelect/Field.vue'
import SingleSelect from '@/components/fields/select/Field.vue'
import ComponentEditorDialog from '@/components/ComponentEditorDialog.vue'
import * as monaco from 'monaco-editor'
import MonacoEditor, { editorEnv } from '@/plugins/monaco'
import EditSidebar from './EditSidebar.vue'
import EditOptions from './EditOptions.vue'
import { confirmDelete } from '@/components/dialogs'

@Component({
  components: {
    Loading,
    Fields,
    ComponentSelect,
    CollectionFieldSelect,
    SingleSelect,
    MonacoEditor,
    ComponentEditorDialog,
    EditSidebar,
    EditOptions
  },
  apollo: {
    savedBlock: {
      query: gql`
        query getVueBlock($vueBlockId: ID) {
          savedBlock: vueBlock(vueBlockId: $vueBlockId) {
            ...VueBlock
          }
        }
        ${VueBlockFragment}
      `,
      variables() {
        return {
          vueBlockId: this.componentId
        }
      }
    }
  }
})
export default class BlockEditorEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) componentId!: string
  activeTab = 'options'
  drawer = false

  saving = false
  codeEditorOptions = {
    automaticLayout: true
  }

  savedBlock: Readonly<VueBlock> | null = null
  block: Partial<VueBlock> = {}

  inlineEditComponentType = ''
  inlineEditComponentId = ''
  inlineEditComponentOpen = false
  editApiCallDialogOpen = false

  newApiCall = {
    name: '',
    title: ''
  }

  sectionHeaders: Record<string, string> = {
    options: 'Opciones',
    template: 'Plantilla',
    script: 'Script',
    style: 'Estilo',
    data: 'Data',
    createApiCall: 'Crear llamada de API'
  }

  get sectionHeader() {
    if (this.activeApiCall) {
      return `API: ${this.activeApiCall.title || this.activeApiCall.name}`
    }
    return this.sectionHeaders[this.activeTab] || ''
  }

  get isNewApiCallNameValid() {
    return (
      this.newApiCall.name.length > 0 &&
      /^[a-zA-Z][a-zA-Z0-9]*$/.test(this.newApiCall.name) &&
      !(this.block.apiCalls || []).find(
        (apiCall) => apiCall.name === this.newApiCall.name
      )
    )
  }

  get activeApiCall() {
    if (!this.activeTab.startsWith('apiCall-')) return undefined
    const apiCallName = this.activeTab.split('-')[1]
    return (this.block.apiCalls || []).find(
      (apiCall) => apiCall.name === apiCallName
    )
  }

  set activeApiCall(value: VueBlockApiCall | undefined) {
    if (!this.activeTab.startsWith('apiCall-')) return
    const apiCallName = this.activeTab.split('-')[1]
    const apiCall = (this.block.apiCalls || []).find(
      (apiCall) => apiCall.name === apiCallName
    )
    Object.assign(apiCall, value)
  }

  @Watch('savedBlock')
  update(newData: VueBlock) {
    this.$set(this, 'block', _cloneDeep(this.savedBlock))
  }

  mounted() {
    //  Launch drawer if no component is selected
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.drawer = true
    }
  }

  createNewApiCall() {
    this.block.apiCalls = this.block.apiCalls || []
    this.block.apiCalls.push({
      name: this.newApiCall.name,
      title: this.newApiCall.title,
      script: `// Este script puede invocarse desde cualquier método Vue.js y se ejecuta en el servidor
// Todas las funciones para consultar y manipular la base de datos se encuentran disponibles.
// Para invocar esta llamada de API, utiliza el siguiente código desde cualquier método asincrónico:
//   this.$api.${this.newApiCall.name}({ parametro1: 'valor', parametro2: 'valor' })
// Los parámetros llegan al script como la variable "params"

// El valor retornado se envia como resultado de la llamada a la función de API
return {
  status: 'ok'
}
`
    })
    this.activeTab = 'apiCall-' + this.newApiCall.name
    this.newApiCall.name = ''
    this.newApiCall.title = ''
  }

  async deleteApiCall() {
    if (!this.activeApiCall) return
    if (
      !(await confirmDelete(
        `¿Seguro que quieres eliminar la llamada de API "${
          this.activeApiCall.title || this.activeApiCall.name
        }"?`
      ))
    ) {
      return
    }
    this.editApiCallDialogOpen = false
    this.activeTab = 'createApiCall'
    this.block.apiCalls!.splice(
      this.block.apiCalls!.indexOf(this.activeApiCall!),
      1
    )
  }

  async save() {
    if (!this.block || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($vueBlockId: ID, $vueBlock: UpdateVueBlockInput) {
            updateVueBlock(vueBlockId: $vueBlockId, vueBlock: $vueBlock) {
              ...VueBlock
            }
          }
          ${VueBlockFragment}
        `,
        // Parameters
        variables: {
          vueBlockId: this.componentId,
          vueBlock: cleanData(this.block, VueBlockUpdateFragment)
        }
      })

      this.$emit('updated')
      this.$emit('save', result.data.updateBlock)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  editorDidMount(editor: monaco.editor.IStandaloneCodeEditor) {
    editorEnv.environmentId = this.environmentId
    // Options
    const model = editor.getModel()
    model?.updateOptions({
      tabSize: 2,
      insertSpaces: true
    })

    // Actions
    editor.addAction({
      id: 'save',
      label: 'Guardar Cambios',
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S],
      run: () => this.save()
    })
    // Events
    editorEnv.onEditComponent = (componentType, componentId) => {
      this.inlineEditComponentOpen = true
      this.inlineEditComponentType = componentType
      this.inlineEditComponentId = componentId
    }
  }
}
