var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"white--text main-text mt-4",staticStyle:{"max-width":"960px"}},[_c('h1',{staticClass:"text-h4",domProps:{"textContent":_vm._s(_vm.block.name)}}),_c('h2',{staticClass:"mt-4 overline"},[_vm._v("Opciones Básicas")]),_c('Fields',{attrs:{"schema":{
      title: {
        type: 'string',
        label: 'Título',
        description: 'Opcional. Se mostrará en las vistas.',
        optional: true,
        sizeSmall: '12',
        fieldOptions: {
          color: 'green'
        },
        newDesign: true
      }
    }},model:{value:(_vm.block),callback:function ($$v) {_vm.block=$$v},expression:"block"}}),_c('h2',{staticClass:"mt-4 overline"},[_vm._v("Auto-Actualización")]),_c('Fields',{attrs:{"schema":{
      autoUpdateData: {
        type: 'boolean',
        label:
          'Activar eventos de actualización de data (se llama la funcion onData del script)',
        optional: true,
        sizeSmall: '12',
        fieldType: 'checkbox',
        fieldOptions: {
          color: 'green'
        },
        newDesign: true
      }
    }},model:{value:(_vm.block),callback:function ($$v) {_vm.block=$$v},expression:"block"}}),(_vm.block.autoUpdateData)?_c('Fields',{staticStyle:{"margin-top":"-24px"},attrs:{"schema":{
      injectNewData: {
        type: 'boolean',
        label:
          'Inyectar nuevos datos al actualizar (sobreescribe la data por completo cada que hay una actualizacion)',
        optional: true,
        sizeSmall: '12',
        fieldType: 'checkbox',
        fieldOptions: {
          color: 'green'
        },
        newDesign: true
      }
    }},model:{value:(_vm.block),callback:function ($$v) {_vm.block=$$v},expression:"block"}}):_vm._e(),_c('h2',{staticClass:"mt-4 overline"},[_vm._v("Acciones")]),_c('v-container',{staticClass:"mb-8",attrs:{"fluid":""}},[_c('v-btn',{staticClass:"mx-1 action-btn",attrs:{"outlined":"","block":""},on:{"click":_vm.rename}},[_c('v-list-item',[_c('v-icon',{staticClass:"mx-4",attrs:{"size":"48"}},[_vm._v("text_format")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Renombrar Bloque Vue.js")]),_c('v-list-item-subtitle',[_vm._v("Cambiar el nombre identificador")])],1)],1)],1),_c('v-btn',{staticClass:"mt-4 mx-1 action-btn",attrs:{"outlined":"","block":""},on:{"click":_vm.duplicate}},[_c('v-list-item',[_c('v-icon',{staticClass:"mx-4",attrs:{"size":"48"}},[_vm._v("content_copy")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Duplicar Bloque Vue.js")]),_c('v-list-item-subtitle',[_vm._v("Crear un nuevo bloque idéntico a este")])],1)],1)],1),_c('v-btn',{staticClass:"mt-4 mx-1 action-btn",attrs:{"color":"warning","outlined":"","block":""},on:{"click":_vm.deleteItem}},[_c('v-list-item',[_c('v-icon',{staticClass:"mx-4",attrs:{"size":"48","color":"warning"}},[_vm._v("delete")]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"warning--text"},[_vm._v("Eliminar Bloque Vue.js")]),_c('v-list-item-subtitle',{staticClass:"warning--text"},[_vm._v("Eliminar por completo este bloque del sistema")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }